.survey {
  display: flex;
  align-items: stretch;
  height: 100%;
  min-height: 740px;
  @include r(1000) {
    flex-direction: column;
    min-height: auto;
  }
  @include h(645) {
    min-height: 645px;
  }

  &__image {
    flex: 0 0 46.25%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background-color: #4671db;
    @include r(1000) {
      flex: 0 0 auto;
      height: 300px;
      padding: 20px;
    }
    @include r(599) {
      height: 200px;
      padding: 15px;
    }
    @include r(460) {
      height: 125px;
      padding: 10px;
    }
    @include r(359) {
      height: 70px;
      padding: 8px;
    }
    @media (min-height: 680px) and (max-width: 1000px) {
      height: 200px;
    }
    @media (min-height: 780px) and (max-width: 1000px) {
      height: 250px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__content {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 30px;
    @include r(1250) {
      padding: 40px 20px;
    }
    @include r(1000) {
      padding: 20px 20px 35px 20px;
      display: block;
    }
    @include r(599) {
      padding: 15px 18px 30px 18px;
    }
    @include r(460) {
      padding-bottom: 17px;
      padding-top: 10px;
    }
    @include r(359) {
      padding-bottom: 12px;
    }
  }

  &__container {
    width: 100%;
    @include r(1000) {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__logo {
    position: relative;
    z-index: 2;
    width: 200px;
    display: block;
    margin: 0 auto;
    @include h(880) {
      width: 185px;
    }
    @include r(1440) {
      width: 185px;
    }
    @include r(1000) {
      flex: 0 0 auto;
    }
    @include r(599) {
      width: 150px;
    }
    @include r(460) {
      width: 100px;
    }
    @include r(460) {
      width: 80px;
    }
    @include r(359) {
      width: 70px;
      height: 70px;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      content: "";
      width: 232px;
      height: 232px;
      background-color: #fff;
      box-shadow: inset 0 -10px 15px rgba(108, 49, 151, 0.03);
      @include h(880) {
        width: 217px;
        height: 217px;
      }
      @include r(1440) {
        width: 217px;
        height: 217px;
      }
      @include r(599) {
        width: 176px;
        height: 176px;
      }
      @include r(460) {
        width: 116px;
        height: 116px;
      }
      @include r(460) {
        width: 86px;
        height: 86px;
      }
      @include r(359) {
        width: 76px;
        height: 76px;
      }
    }

    &-wrapper {
      display: block;
      z-index: 2;
      position: relative;
      padding: 16px;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 1px 10px 10px rgba(108, 49, 151, 0.08),
        10px 10px 20px rgba(108, 49, 151, 0.06),
        30px 30px 30px rgba(108, 49, 151, 0.04);
      @include h(880) {
        width: 185px;
        height: 185px;
      }
      @include r(1440) {
        width: 185px;
        height: 185px;
      }
      @include r(599) {
        width: 150px;
        height: 150px;
        padding: 12px;
      }
      @include r(460) {
        width: 100px;
        height: 100px;
        padding: 8px;
      }
      @include r(460) {
        width: 80px;
        height: 80px;
      }
      @include r(359) {
        width: 70px;
        height: 70px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__form {
    position: relative;
    z-index: 1;
    margin: -100px auto 0 auto;
    width: 100%;
    max-width: 778px;
    background-color: #fff;
    box-shadow: 1px 10px 10px rgba(108, 49, 151, 0.08),
      10px 10px 20px rgba(108, 49, 151, 0.06),
      30px 30px 30px rgba(108, 49, 151, 0.04);
    padding: 135px 50px 45px 50px;
    border-radius: 10px;
    min-height: 640px;
    @include h(880) {
      min-height: 520px;
      margin-top: -85px;
      padding-top: 120px;
    }
    @include r(1440) {
      min-height: 520px;
      margin-top: -85px;
      padding-top: 120px;
    }
    @include r(1250) {
      padding: 120px 30px 45px 30px;
    }
    @include r(1150) {
      padding: 120px 20px 45px 20px;
    }
    @include r(1000) {
      flex: 1 1 auto;
      min-height: auto;
    }
    @include r(599) {
      margin-top: -75px;
      padding-top: 110px;
    }
    @include r(460) {
      margin-top: -45px;
      padding: 70px 16px 30px 16px;
    }
    @include r(359) {
      padding: 55px 16px 20px 16px;
    }
    @include h(645) {
      min-height: 480px;
      margin-top: -85px;
      padding: 110px 30px 35px 20px;
    }
  }

  &__nav {
    position: absolute;
    top: -76px;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  &__arrow {
    display: block;
    position: absolute;
    top: 0;
    background: {
      color: #fff;
      repeat: no-repeat;
      position: center;
    }
    border: 1px solid #ff2d38;
    box-shadow: 0 6.4px 12.8px rgba(255, 45, 56, 0.32);
    border-radius: 50%;
    width: 56px;
    height: 56px;
    cursor: pointer;
    transition-property: box-shadow;
    transition-duration: 0.2s;
    @include r(599) {
      box-shadow: none;
      border: none;
      width: 40px;
      height: 40px;
      background-size: 24px;
      top: 10px;
    }
    @include r(460) {
      top: 38px;
      background-color: transparent;
    }
    @include r(359) {
      top: 42px;
    }

    &:hover {
      box-shadow: 0 6.4px 12.8px rgba(255, 45, 56, 0.12);
      @include r(599) {
        box-shadow: none;
      }
    }

    &--prev {
      background-image: url("#{$images-path}/icon-prev.svg");
      left: 0;
      @include r(599) {
        left: -8px;
      }
    }

    &--next {
      background-image: url("#{$images-path}/icon-next.svg");
      right: 0;
      @include r(599) {
        right: -8px;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    @include h(645) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    @include r(1000) {
      flex: 0 0 auto;
    }
    @include r(599) {
      margin-top: 30px;
    }
    @include r(460) {
      margin-top: 18px;
    }
    @include r(359) {
      margin-top: 12px;
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.2;
      color: #2f3d48;
      @include r(359) {
        font-size: 13px;
      }
    }

    img {
      display: inline-block;
      margin-left: 16px;
      max-width: 120px;
      width: 100%;
      @include r(460) {
        max-width: 80px;
        margin-left: 6px;
      }
      @include r(359) {
        max-width: 70px;
      }
    }
  }

  &__name {
    display: block;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.3;
    color: #929cb9;
    @include r(599) {
      font-size: 14px;
    }
    @include r(359) {
      font-size: 13px;
    }
  }

  &__progress {
    &-label {
      display: block;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.2;
      color: #929cb9;
      @include r(460) {
        font-size: 13px;
      }
      @include r(359) {
        font-size: 12px;
      }
    }

    &-bar {
      margin-top: 8px;
      width: 100%;
      height: 14px;
      background-color: #f9f9f9;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.03);
      border-radius: 30px;
      padding: 4px 5px;
      @include r(460) {
        margin-top: 6px;
        height: 12px;
      }
      @include r(359) {
        height: 11px;
      }

      span {
        display: block;
        height: 100%;
        background: linear-gradient(118.46deg, #2f3d48 62.82%, #6e7d89 155.65%);
        border-radius: 30px;
        transition-property: width;
        transition-duration: 0.7s;
      }
    }
  }

  &__title {
    display: block;
    margin-top: 15px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.4;
    color: #2f3d48;
    @include r(460) {
      font-size: 17px;
      margin-top: 14px;
    }
    @include r(359) {
      font-size: 15px;
      margin-top: 10px;
    }

    &.lg {
      font-size: 30px;
      @include r(599) {
        font-size: 26px;
      }
      @include r(460) {
        font-size: 20px;
      }
      @include r(359) {
        font-size: 20px;
      }
    }
  }

  &__subtitle {
    display: block;
    margin-top: 80px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.4;
    color: #2f3d48;

    @include r(767) {
      margin-top: 60px;
    }
  }

  &__link {
    display: inline-block;
    margin-top: 120px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
    color: #4671db;
    border-bottom: 1px solid #4671db;
    transition-property: border-bottom-color;
    transition-duration: 0.3s;
    @include r(767) {
      margin-top: 80px;
    }

    &:hover {
      border-bottom-color: transparent;
    }

    @include r(599) {
      font-size: 16px;
    }
  }

  &__text {
    display: block;
    margin-top: 10px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    color: #2f3d48;
    @include r(599) {
      font-size: 16px;
    }
    @include r(460) {
      font-size: 13px;
    }
    @include r(359) {
      font-size: 12px;
      margin-top: 6px;
    }
  }

  &__rating {
    padding-bottom: 30px;
    position: relative;
    max-width: 500px;
    width: 100%;
    margin: 26px auto 0 auto;
    @include r(1150) {
      padding-bottom: 26px;
      max-width: 452px;
    }
    @include r(599) {
      max-width: 350px;
      padding-bottom: 10px;
    }
    @include r(460) {
      max-width: 286px;
      padding-bottom: 2px;
      margin-top: 14px;
    }
    @include r(359) {
      max-width: 260px;
      margin-top: 0;
    }

    &-stars {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
      margin: -10px;
      @include r(1150) {
        margin: -6px;
      }
      @include r(460) {
        margin: -5px;
      }
      @include r(359) {
        margin: -4px;
      }

      & :checked ~ label.bad {
        svg {
          path {
            fill: #ff2d38;
          }
        }
      }

      & :checked ~ label.great {
        svg {
          path {
            fill: #17c981;
          }
        }
      }

      input {
        display: none;
      }
    }

    &-star {
      flex: 0 0 auto;
      width: 83px;
      margin: 10px;
      position: relative;
      font-size: 0;
      cursor: pointer;
      @include h(880) {
        width: 76px;
      }
      @include r(1440) {
        width: 76px;
      }
      @include r(1150) {
        margin: 6px;
        width: 72px;
      }
      @include r(599) {
        width: 56px;
      }
      @include r(460) {
        width: 44px;
        margin: 5px;
      }
      @include r(359) {
        width: 42px;
        margin: 4px;
      }

      svg {
        width: 100%;
      }

      &.bad {
        &:hover,
        &:hover ~ label {
          svg {
            path {
              fill: #ff2d38;
            }
          }
        }
      }

      &.great {
        &:hover,
        &:hover ~ label {
          svg {
            path {
              fill: #17c981;
            }
          }
        }
      }

      &.disabled {
        opacity: 0.5;
      }

      svg {
        path {
          transition-property: fill;
          transition-duration: 0.3s;
        }
      }
    }

    &-label {
      display: block;
      position: absolute;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 1;
      bottom: 0;

      @include r(1150) {
        font-size: 18px;
      }
      @include r(599) {
        font-size: 14px;
      }
      @include r(460) {
        font-size: 11px;
      }

      &.bad {
        left: 23px;
        color: #ff2d38;
        @include h(880) {
          left: 36px;
        }
        @include r(1440) {
          left: 36px;
        }
        @include r(1150) {
          left: 38px;
        }
        @include r(599) {
          left: 24px;
        }
        @include r(359) {
          left: 16px;
        }
      }

      &.great {
        right: 16px;
        color: #17c981;
        @include h(880) {
          right: 30px;
        }
        @include r(1440) {
          right: 30px;
        }
        @include r(1150) {
          right: 32px;
        }
        @include r(599) {
          right: 20px;
        }
        @include r(359) {
          right: 12px;
        }
      }
    }
  }

  &__action {
    display: flex;
    justify-content: center;
  }

  &__radioboxes {
    max-width: 220px;
    width: 100%;
    margin: 40px auto 0 auto;
    @include r(460) {
      margin-top: 30px;
      max-width: 250px;
    }
    @include r(359) {
      margin-top: 15px;
      max-width: 210px;
    }

    &.isNotValidate {
      .radiobox__title {
        color: #ff2d38;
      }
    }

    .radiobox {
      margin-bottom: 28px;
      @include h(880) {
        margin-bottom: 22px;
      }
      @include r(1440) {
        margin-bottom: 22px;
      }
      @include r(460) {
        margin-bottom: 15px;
      }
      @include r(359) {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__wrong {
    display: flex;
    align-items: flex-end;
    .survey__field {
      width: calc(75% - 10px);
      @include r(460) {
        width: calc(50% - 10px);
      }
    }
    .survey__uploadPhoto {
      margin-left: 20px;
      width: calc(25% - 10px);
      .textLabel {
          display: block;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 1.2;
          color: #2f3d48;
          margin-bottom: 5px;
          @include r(359) {
            font-size: 12px;
          }
      }
      .photoLabel {
        position: relative;
        display: block;
        height: 126px;
        border: 1px solid #D5D8DE;
        background-color: #F7F7FA;
        border-radius: 6px;
        cursor: pointer;
        &:before {
          content: url("#{$images-path}/icon-upload-photo.svg");
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
        &.error {
            border-color: #ff2d38;
          &:before {
            content: url("#{$images-path}/icon-upload-photo-error.svg");
          }
        }
        &.withImg {
          &:before {
            display: none;
          }
        }
      }
      input {
        display: none;
      }

      @include r(460) {
        width: calc(50% - 10px);
        margin-left: 10px;
        margin-top: 20px;
      }

    }
  }


  &__field {
    margin-top: 40px;
    @include h(880) {
      margin-top: 20px;
    }
    @include r(1440) {
      margin-top: 20px;
    }
    @include r(359) {
      margin-top: 10px;
    }

    label {
      display: block;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.2;
      color: #2f3d48;
      margin-bottom: 5px;
      @include r(359) {
        font-size: 12px;
      }
    }
  }

  .btn-red {
    display: block;
    margin: 50px auto 0 auto;
    @include h(880) {
      margin-top: 50px;
    }
    @include r(1440) {
      margin-top: 50px;
    }
    @include r(1000) {
      max-width: 240px;
      width: 100%;
      margin-top: 50px;
    }
    @include r(359) {
      margin-top: 50px;
      max-width: 220px;
    }
  }

  .errorSignWrapper {
    position: absolute;
    width: inherit;
  }

  .errorSign {
    width: 100%;
    word-break: break-word;
    color: #ff2d38;
    font-size: 10px;
    font-weight: 700;
    animation: 1.2s ease-in-out 0s normal none running trambling-animation;
    @include r(359) {
      font-size: 9px;
    }

    span {
      text-align: center;
      display: block;
    }
  }

  @keyframes trambling-animation {
    0%,
    50%,
    100% {
      transform: rotate(0deg);
    }
    10%,
    30% {
      transform: rotate(-0.5deg);
    }
    20%,
    40% {
      transform: rotate(0.5deg);
    }
  }

  .marginTop60 {
    margin-top: 60px;
    @include h(880) {
      margin-top: 30px;
    }
    @include r(1440) {
      margin-top: 30px;
    }
    @include r(1000) {
      margin-top: 12px;
    }
    @include r(359) {
      margin-top: 5px;
    }
  }

  .marginTop120 {
    margin-top: 120px;
    @include h(880) {
      margin-top: 60px;
    }
    @include r(1440) {
      margin-top: 60px;
    }
  }

  &__expired {
    display: block;
    text-align: center;
    margin-top: 40px;
  }

  &__step {
    &.lastStepBad {
      .errorSignWrapper {
        min-height: 18px;
      }
    }
  }

  &__restaurantClosed {
    max-width: 220px;
    margin: 30px auto 0 auto;
    span {
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 1.2;
      color: #2F3D48;
    }
    &-action {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 5px;

      p {
        display: inline-block;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.2;
        color: #2F3D48;
      }
    }
    &-btn {
      border: 2px solid #2F3D48;
      margin-left: 10px;
      position: relative;
      top: -2px;
      border-radius: 30px;
      display: inline-block;
      flex: 0 0 auto;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 1;
      color: #2F3D48;
      padding: 2px 10px;
      transition-property: background-color;
      transition-duration: .3s;
      &:hover {
        background-color: rgba(#2F3D48, .1);
      }
    }
  }
}
