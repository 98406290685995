@mixin r($width) {
	@media only screen and (max-width: "#{$width}px") {
		@content;
	}
}
@mixin h($height) {
	@media only screen and (max-height: "#{$height}px") {
		@content;
	}
}
