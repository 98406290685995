.field-textarea {
  display: block;
  width: 100%;
  background-color: #f7f7fa;
  border: 1px solid #d5d8de;
  border-radius: 6px;
  height: 126px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #2f3d48;
  padding: 16px 18px;
  resize: none;
  @include r(460) {
    height: 126px;
    padding: 10px 12px;
    font-size: 12px;
  }
  @include r(359) {
    height: 126px;
  }
  &::placeholder {
    color: #2f3d48;
  }
}

.radiobox {
  display: flex;
  align-items: center;
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .radiobox__mark::before {
      opacity: 1;
    }
  }

  &__mark {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 2px solid #ff2d38;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    @include r(360) {
      width: 18px;
      height: 18px;
    }

    &::before {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #ff2d38;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition-property: opacity;
      transition-duration: 0.2s;
      @include r(360) {
        width: 10px;
        height: 10px;
      }
    }
  }

  &__title {
    flex: 1 1 auto;
    padding-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: #2f3d48;
    @include r(360) {
      font-size: 13px;
    }
  }
}

.btn-red {
  display: inline-block;
  position: relative;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  color: rgb(255, 255, 255);
  box-shadow: 0px 8px 16px rgba(255, 45, 56, 0.32);
  border-radius: 30px;
  padding: 15px 20px;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  @include r(460) {
    padding: 13px 18px;
    font-size: 15px;
  }
  @include r(359) {
    padding: 10px 14px;
    font-size: 14px;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    content: "";
    background-image: linear-gradient(
      105.63deg,
      rgb(255, 45, 56) 39.07%,
      rgb(240, 134, 140) 96.81%
    );
    z-index: 2;
    transition-property: opacity;
    transition-duration: 0.3s;
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    content: "";
    background-image: linear-gradient(
      105.63deg,
      rgb(255, 45, 56) 39.07%,
      rgb(255, 45, 56) 96.81%
    );
    z-index: 1;
  }
  span {
    display: inline-block;
    position: relative;
    z-index: 3;
  }
  &:hover {
    &::before {
      opacity: 0;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    width: 219px;

    @include r(1000) {
      max-width: 240px;
      width: 100%;
    }
    @include r(359) {
      max-width: 220px;
    }
  }
}

.animate-points {
  display: inline-flex;
  align-items: center;
  margin-left: 5px;

  .point-one {
    opacity: 0;
    animation: dot 1.3s infinite;
    animation-delay: 0s;
  }

  .point-two {
    opacity: 0;
    animation: dot 1.3s infinite;
    animation-delay: 0.2s;
  }

  .point-three {
    opacity: 0;
    animation: dot 1.3s infinite;
    animation-delay: 0.3s;
  }

  @-webkit-keyframes dot {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes dot {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
