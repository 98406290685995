.PreloaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: #fff;
  overflow: hidden;

  .CircularProgress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
    svg {
      circle {
        color: #FF2D38;
      }
    }
  }
}